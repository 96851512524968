














































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { readResult, readSamplePageImages } from "@/store/main/getters";
import { formatDate } from "@/date-utils";
import {
  dispatchGetSamplePageImages,
  dispatchGetCompanyInfo,
} from "@/store/main/actions";
import CoaListCard from "@/components/CoaListCard.vue";
import { ICOA, ICompanyInfo } from "@/interfaces";

@Component({
  components: {
    CoaListCard,
  },
})
export default class ResultLandingPage extends Vue {
  loadingImages = false;

  @Prop({ default: [] })
  coas!: ICOA[];

  companyInfo: ICompanyInfo | null = null;

  get result() {
    return readResult(this.$store);
  }

  get testId() {
    return this.result?.test_id ? this.result.test_id : "N/A";
  }

  get testType() {
    return this.result?.test_type?.test_name
      ? this.result.test_type.test_name
      : "N/A";
  }

  get orderId() {
    return this.result?.order_id ? this.result.order_id : "N/A";
  }

  get sampleImages() {
    return readSamplePageImages(this.$store);
  }

  get dateSubmitted() {
    return this.result?.date_submitted ? this.result.date_submitted : "N/A";
  }

  get sampleId() {
    return this.result?.sample_id ? this.result.sample_id : null;
  }

  showImages() {
    const matches = this.sampleImagesMatchRoute();
    const imagesExist = this.sampleImages?.images.length > 0;
    return matches && imagesExist && !this.loadingImages;
  }

  sampleImagesMatchRoute() {
    // check if the images in vuex are for our current sample
    return this.sampleImages?.sample_id === this.sampleId;
  }

  formatDate(dateString: string) {
    return formatDate(dateString, null);
  }

  async getSamplePhotos() {
    if (this.sampleId && !this.sampleImagesMatchRoute()) {
      this.loadingImages = true;
      await dispatchGetSamplePageImages(this.$store, this.sampleId);
      this.loadingImages = false;
    }
  }

  async getCompanyInfo() {
    this.companyInfo =
      (await dispatchGetCompanyInfo(this.$store, {
        test_id: this.result?.test_id,
      })) ?? null;
  }

  @Watch("result")
  async onResultChange() {
    Promise.all([this.getSamplePhotos(), this.getCompanyInfo()]);
  }

  async mounted() {
    if (this.result) {
      await this.onResultChange();
    }
  }
}
